import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import Navbar from './components/Navbar';
import Home from './components/Home';
import Landing from './components/Landing';
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const firebaseConfig = {
  apiKey: 'AIzaSyCdOFSYiJaumIXWUFIctIDGLi9Zw1aAk-4',
  authDomain: 'fitness-diary-65f2e.firebaseapp.com',
  projectId: 'fitness-diary-65f2e',
  storageBucket: 'fitness-diary-65f2e.appspot.com',
  messagingSenderId: '776383968383',
  appId: '1:776383968383:web:8495f68a50dbb2acf8bb4a',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const theme = createTheme({
  palette: {
    primary: {
      main: '#113f67',
    },
    background: {
      default: '#f5f5f7',
    },
  },
  typography: {
    h3: {
      color: '#113f67',
    },
    h5: {
      color: '#113f67',
    },
    h6: {
      color: '#113f67',
    },
    subtitle1: {
      color: '#113f67',
    },
  },
});

export default function App() {
  const [user, loading] = useAuthState(auth);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <Box
          component="main"
          sx={{ display: 'flex', flexDirection: 'column', p: 3 }}
        >
          <BrowserRouter>
            <Navbar auth={auth} user={user} />
            <Container sx={{ p: 0 }}>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : user ? (
                <Home user={user} db={db} />
              ) : (
                <Landing auth={auth} />
              )}
            </Container>
          </BrowserRouter>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
