import React, { useState } from 'react';
import { doc, setDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import moment from 'moment';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { GENERAL_FEELING_LABELS } from '../utils';
import { NEURO_SYMPTOMS_LABELS } from '../utils';
import { GASTRO_SYMPTOMS_LABELS } from '../utils';
import { PHYSICAL_INTENSITY_LABELS } from '../utils';
import { INTELLECTUAL_INTENSITY_LABELS } from '../utils';

function formatDate(currDate) {
  return `${currDate
    .date()
    .toLocaleString('en-US', { minimumIntegerDigits: 2 })}${(
    currDate.month() + 1
  ).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })}${currDate.year()}`;
}

function idToDate(id) {
  const momentTime = moment(id, 'DDMMYYYY');
  momentTime.add(12, 'hours');
  return Timestamp.fromMillis(momentTime.valueOf());
}

export default function Entry({
  user,
  db,
  isOpen,
  setIsOpen,
  nutrition,
  extras,
  updateData,
  setEntries,
  settings,
}) {
  const currDate = moment(Date.now());
  const [data, setData] = useState({
    id: updateData?.id ?? formatDate(currDate),
    createdAt: updateData?.createdAt ?? Timestamp.fromMillis(currDate),
    date: updateData?.id
      ? idToDate(updateData.id)
      : Timestamp.fromMillis(currDate),
    breakfast: updateData?.breakfast ?? [],
    lunch: updateData?.lunch ?? [],
    dinner: updateData?.dinner ?? [],
    snacks: updateData?.snacks ?? [],
    supplements: updateData?.supplements ?? [],
    water: updateData?.water ?? null,
    exercise: updateData?.exercise ?? null,
    alcohol: updateData?.alcohol ?? null,
    energy: updateData?.energy ?? null,
    dizziness: updateData?.dizziness ?? null,
    mitotane: updateData?.mitotane ?? null,
    cortisone: updateData?.cortisone ?? null,
    general_feeling: updateData?.general_feeling ?? null,
    physical_feeling: updateData?.physical_feeling ?? null,
    psychological_feeling: updateData?.psychological_feeling ?? null,
    neurological_feeling: updateData?.neurological_feeling ?? null,
    gastrointestinal_feeling: updateData?.gastrointestinal_feeling ?? null,
    comments: updateData?.comments ?? '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanData = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null)
    );
    await setDoc(doc(db, 'users', user.uid, 'diary', data.id), {
      ...cleanData,
      date: idToDate(data.id),
    });

    if (updateData) {
      setEntries((prevEntries) => {
        const index = prevEntries.findIndex((ee) => ee.id === data.id);
        return [
          ...prevEntries.slice(0, index),
          data,
          ...prevEntries.slice(index + 1),
        ];
      });
    } else {
      setEntries((prevEntries) => {
        let index = 0;

        while (
          index < prevEntries.length &&
          prevEntries[index].date.seconds > data.date.seconds
        ) {
          index++;
        }

        return [
          ...prevEntries.slice(0, index),
          data,
          ...prevEntries.slice(index + 1),
        ];
      });
    }

    setIsOpen(false);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    await deleteDoc(doc(db, 'users', user.uid, 'diary', data.id));

    setEntries((prevEntries) => {
      const index = prevEntries.findIndex((ee) => ee.id === data.id);
      return [...prevEntries.slice(0, index), ...prevEntries.slice(index + 1)];
    });

    setIsOpen(false);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleIdChange = (value) => {
    setData({
      ...data,
      id: value,
      date: idToDate(value),
    });
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={() => setIsOpen(false)}
      maxWidth="md"
    >
      <DialogContent>
        <Stack spacing={2}>
          <MobileDatePicker
            label="Date"
            inputFormat="DD/MM/YYYY"
            value={moment(data.id, 'DDMMYYYY')}
            onChange={(val) => handleIdChange(formatDate(val))}
            renderInput={(params) => <TextField {...params} />}
          />
          {settings.food && (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Divider>Breakfast</Divider>
              <Autocomplete
                onChange={(e, value) => handleChange('breakfast', value)}
                defaultValue={data.breakfast}
                multiple
                options={nutrition}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search foods or drinks"
                    placeholder="Favorites"
                  />
                )}
                sx={{ width: '300' }}
              />
            </Paper>
          )}
          {settings.food && (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Divider>Lunch</Divider>
              <Autocomplete
                onChange={(e, value) => handleChange('lunch', value)}
                defaultValue={data.lunch}
                multiple
                options={nutrition}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search foods or drinks"
                    placeholder="Favorites"
                  />
                )}
                sx={{ width: '300' }}
              />
            </Paper>
          )}
          {settings.food && (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Divider>Dinner</Divider>
              <Autocomplete
                onChange={(e, value) => handleChange('dinner', value)}
                defaultValue={data.dinner}
                multiple
                options={nutrition}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search foods or drinks"
                    placeholder="Favorites"
                  />
                )}
                sx={{ width: '300' }}
              />
            </Paper>
          )}
          {settings.food && (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Divider>Snacks</Divider>
              <Autocomplete
                onChange={(e, value) => handleChange('snacks', value)}
                defaultValue={data.snacks}
                multiple
                options={nutrition}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search foods or drinks"
                    placeholder="Favorites"
                  />
                )}
                sx={{ width: '300' }}
              />
            </Paper>
          )}

          {settings.exercise && (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Grid container spacing={2}>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Water</InputLabel>
                    <Select
                      value={data.water}
                      label="Water"
                      onChange={(e) => handleChange('water', e.target.value)}
                    >
                      {extras.water.map((w, i) => (
                        <MenuItem key={i} value={w}>
                          {w}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Exercise</InputLabel>
                    <Select
                      value={data.exercise}
                      label="Exercise"
                      onChange={(e) => handleChange('exercise', e.target.value)}
                    >
                      {new Array(10).fill().map((w, i) => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Supplements</InputLabel>
                    <Select
                      value={data.supplements}
                      multiple
                      label="Supplements"
                      onChange={(e) =>
                        handleChange('supplements', e.target.value)
                      }
                    >
                      {extras.supplements.map((w, i) => (
                        <MenuItem key={i} value={w}>
                          {w}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Alcohol</InputLabel>
                    <Select
                      value={data.alcohol}
                      label="Alcohol"
                      onChange={(e) => handleChange('alcohol', e.target.value)}
                    >
                      {new Array(5).fill().map((w, i) => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12} md={3} mdOffset={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Energy</InputLabel>
                    <Select
                      value={data.energy}
                      label="Energy"
                      onChange={(e) => handleChange('energy', e.target.value)}
                    >
                      {new Array(10).fill().map((w, i) => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Dizziness</InputLabel>
                    <Select
                      value={data.dizziness}
                      label="Dizziness"
                      onChange={(e) =>
                        handleChange('dizziness', e.target.value)
                      }
                    >
                      {new Array(10).fill().map((w, i) => (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          )}

          {settings.disease && (
            <Paper elevation={3} sx={{ p: 4 }}>
              <Grid container spacing={2}>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Mitotane</InputLabel>
                    <Select
                      label="Mitotane"
                      value={data.mitotane}
                      onChange={(e) => handleChange('mitotane', e.target.value)}
                    >
                      {new Array(13).fill().map((w, i) => (
                        <MenuItem key={i / 2} value={i / 2}>
                          {i / 2}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Cortisone</InputLabel>
                    <Select
                      label="Cortisone"
                      value={data.cortisone}
                      onChange={(e) =>
                        handleChange('cortisone', e.target.value)
                      }
                    >
                      {new Array(13).fill().map((w, i) => (
                        <MenuItem key={i / 2} value={i / 2}>
                          {i / 2}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>General Feeling</InputLabel>
                    <Select
                      label="General Feeling"
                      value={data.general_feeling}
                      onChange={(e) =>
                        handleChange('general_feeling', e.target.value)
                      }
                    >
                      {GENERAL_FEELING_LABELS.map((label, i) => (
                        <MenuItem key={i} value={i}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Physical Intensity</InputLabel>
                    <Select
                      label="Physical Intensity"
                      value={data.physical_feeling}
                      onChange={(e) =>
                        handleChange('physical_feeling', e.target.value)
                      }
                    >
                      {PHYSICAL_INTENSITY_LABELS.map((label, i) => (
                        <MenuItem key={i} value={i}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3} mdOffset={1}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Intellectual Intensity</InputLabel>
                    <Select
                      label="Intellectual Intensity"
                      value={data.psychological_feeling}
                      onChange={(e) =>
                        handleChange('psychological_feeling', e.target.value)
                      }
                    >
                      {INTELLECTUAL_INTENSITY_LABELS.map((label, i) => (
                        <MenuItem key={i} value={i}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Neurological Symptoms</InputLabel>
                    <Select
                      label="Neurological Symptoms"
                      value={data.neurological_feeling}
                      onChange={(e) =>
                        handleChange('neurological_feeling', e.target.value)
                      }
                    >
                      {NEURO_SYMPTOMS_LABELS.map((label, i) => (
                        <MenuItem key={i} value={i}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel>Gastrointestinal Symptoms</InputLabel>
                    <Select
                      label="Gastrointestinal Symptoms"
                      value={data.gastrointestinal_feeling}
                      onChange={(e) =>
                        handleChange('gastrointestinal_feeling', e.target.value)
                      }
                    >
                      {GASTRO_SYMPTOMS_LABELS.map((label, i) => (
                        <MenuItem key={i} value={i}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          )}

          <Paper elevation={3} sx={{ p: 4 }}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  multiline
                  name="comments"
                  label="Comments"
                  value={data.comments}
                  onChange={(e) => handleChange('comments', e.target.value)}
                />
              </Grid>
            </Grid>
          </Paper>

          <Grid container spacing={2} xs={12} justifyContent="center">
            <Grid>
              <Button
                color="success"
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Grid>

            {updateData && (
              <Grid>
                <Button
                  color="error"
                  onClick={handleDelete}
                  type="secondray"
                  variant="contained"
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
