export const GENERAL_FEELING_LABELS = [
  'Bad',
  'OKish',
  'Reasonably well',
  'Well',
  'Very well',
];

export const NEURO_SYMPTOMS_LABELS = [
  'None',
  'Mild',
  'Moderate',
  'Severe',
  'Very severe',
];

export const GASTRO_SYMPTOMS_LABELS = [
  'None',
  'Mild sporadic nausea',
  'Mild persistent nausea',
  'Strong nausea',
  'Diarrhea',
];

export const PHYSICAL_INTENSITY_LABELS = [
  'In bed',
  'Mostly relaxing',
  'Limited activity',
  'Reasonably active day',
  'Intense physical activity',
];

export const INTELLECTUAL_INTENSITY_LABELS = [
  'Zero stress',
  'Mostly relaxing',
  'Normally active',
  'Intense day',
  'Very intense day',
];

export const csvFileToArray = (string) => {
  const formattedString = string.replaceAll('\r', '');
  const csvHeader = formattedString
    .slice(0, formattedString.indexOf('\n'))
    .split(',');
  const csvRows = formattedString
    .slice(formattedString.indexOf('\n') + 1)
    .split('\n');

  const array = csvRows.map((i) => {
    const values = i.split(',');
    const obj = csvHeader.reduce((object, header, index) => {
      object[header] = values[index];
      return object;
    }, {});
    return obj;
  });

  return array;
};

export const getIdFromCsvData = () => {};
