import React from 'react';
import moment from 'moment';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';

import { GENERAL_FEELING_LABELS } from '../utils';
import { NEURO_SYMPTOMS_LABELS } from '../utils';
import { GASTRO_SYMPTOMS_LABELS } from '../utils';
import { PHYSICAL_INTENSITY_LABELS } from '../utils';
import { INTELLECTUAL_INTENSITY_LABELS } from '../utils';

function TimelineCardPaper({ title, options }) {
  return (
    <Paper elevation={3} sx={{ px: 2, py: 1 }} style={{ height: '100%' }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Stack spacing={2}>
        {Array.isArray(options) ? (
          options.map((b) => (
            <Typography variant="paragraph" fontSize={14} key={b.id}>
              {b.name}
            </Typography>
          ))
        ) : (
          <Typography variant="paragraph" fontSize={14}>
            {options}
          </Typography>
        )}
      </Stack>
    </Paper>
  );
}

export default function TimelineCard({
  entry,
  settings,
  setUpdateData,
  setIsEntryOpen,
}) {
  return (
    <Grid key={entry.id} item xs={12} lg={6}>
      <Paper sx={{ p: 3 }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h5">
            {moment(entry.id, 'DDMMYYYY').format('MMMM Do YYYY')}
          </Typography>
          <IconButton
            onClick={() => {
              setUpdateData(entry);
              setIsEntryOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Stack>

        <Divider sx={{ my: 1 }} />

        {settings.food && (
          <Grid container spacing={2}>
            <Grid xs={12} md={3}>
              <TimelineCardPaper title="Breakfast" options={entry.breakfast} />
            </Grid>

            <Grid xs={12} md={3}>
              <TimelineCardPaper title="Lunch" options={entry.lunch} />
            </Grid>

            <Grid xs={12} md={3}>
              <TimelineCardPaper title="Dinner" options={entry.dinner} />
            </Grid>

            <Grid xs={12} md={3}>
              <TimelineCardPaper title="Snacks" options={entry.snacks} />
            </Grid>
          </Grid>
        )}

        {settings.food && <Divider sx={{ my: 2 }} />}

        {settings.exercise && (
          <Grid container spacing={2}>
            <Grid xs={6} md={3}>
              <TimelineCardPaper title="Water" options={entry.water} />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper title="Exercise" options={entry.exercise} />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper title="Alcohol" options={entry.alcohol} />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper
                title="Supplements"
                options={entry.supplements}
              />
            </Grid>

            <Grid xs={6} md={3} mdOffset={3}>
              <TimelineCardPaper title="Energy" options={entry.energy} />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper title="Dizziness" options={entry.dizziness} />
            </Grid>
          </Grid>
        )}

        {settings.exercise && <Divider sx={{ my: 2 }} />}

        {settings.disease && (
          <Grid container spacing={2}>
            <Grid xs={6} md={3}>
              <TimelineCardPaper title="Mitotane" options={entry.mitotane} />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper title="Cortisone" options={entry.cortisone} />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper
                title="General Feeling"
                options={GENERAL_FEELING_LABELS[entry.general_feeling]}
              />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper
                title="Physical Intensity"
                options={PHYSICAL_INTENSITY_LABELS[entry.physical_feeling]}
              />
            </Grid>

            <Grid xs={6} md={3} mdOffset={1}>
              <TimelineCardPaper
                title="Intellectual Intensity"
                options={
                  INTELLECTUAL_INTENSITY_LABELS[entry.psychological_feeling]
                }
              />
            </Grid>

            <Grid xs={6} md={3}>
              <TimelineCardPaper
                title="Neurological Symptoms"
                options={NEURO_SYMPTOMS_LABELS[entry.neurological_feeling]}
              />
            </Grid>

            <Grid xs={12} md={3}>
              <TimelineCardPaper
                title="Gastrointestinal Symptoms"
                options={GASTRO_SYMPTOMS_LABELS[entry.gastrointestinal_feeling]}
              />
            </Grid>
          </Grid>
        )}

        {settings.disease && <Divider sx={{ my: 2 }} />}

        {entry.comments && (
          <div>
            {entry.comments.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        )}
      </Paper>
    </Grid>
  );
}
