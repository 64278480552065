import React, { useMemo, useState } from 'react';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import Button from '@mui/material/Button';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { red, grey, blue, green, purple, orange } from '@mui/material/colors';

import { csvFileToArray } from '../utils';

const renderCustomBarLabel = ({ payload, x, y }) => {
  if (!payload.value) {
    return 'No data';
  }

  return (
    <text
      x={x}
      y={y}
      fill="#666"
      dy={15}
      textAnchor="middle"
    >{`${payload.value.substring(0, 2)}/${payload.value.substring(
      2,
      4
    )}`}</text>
  );
};

const getMovingAverage = (data) => {
  const keys = [
    'alcohol',
    'water',
    'energy',
    'dizziness',
    'mitotane',
    'cortisone',
    'general_feeling',
    'physical_feeling',
    'psychological_feeling',
    'neurological_feeling',
    'gastrointestinal_feeling',
  ];

  const result = { ...data[0] };

  keys.forEach((key) => {
    let dataLength = 0;
    let newValue = 0;

    data.forEach((d) => {
      if (d[key] != null) {
        newValue += Number(d[key]);
        dataLength++;
      }
    });

    if (dataLength) {
      newValue /= dataLength;
      result[key] = newValue;
    }
  });

  return result;
};

export default function Dashboard({ user, db, entries, settings }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [checkBoxes, setCheckBoxes] = useState(() => ({
    alcohol: true,
    water: true,
    energy: true,
    dizziness: true,
    mitotane: true,
    cortisone: true,
    general_feeling: true,
    physical_feeling: true,
    psychological_feeling: true,
    neurological_feeling: true,
    gastrointestinal_feeling: true,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [period, setPeriod] = useState(1);

  const data = useMemo(() => {
    console.log(entries);
    const sortedEntries = entries
      .slice()
      .reverse()
      .filter((e) => {
        if (startDate == null && endDate == null) {
          return true;
        }

        const date = new Date(e.date.seconds * 1000);

        if (startDate == null) {
          return date <= endDate;
        }

        if (endDate == null) {
          return date >= startDate;
        }

        return date >= startDate && date <= endDate;
      });

    console.log(sortedEntries);

    if (period > sortedEntries.length || period < 2) {
      return sortedEntries;
    }

    const movingAverages = [];

    for (let x = 0; x + period - 1 < sortedEntries.length; x += 1) {
      movingAverages.push(getMovingAverage(sortedEntries.slice(x, x + period)));
    }

    return movingAverages;
  }, [entries, period, startDate, endDate]);

  const onExportData = () => {
    const headers = [
      'date',
      'mitotane',
      'cortisone',
      'general_feeling',
      'physical_feeling',
      'psychological_feeling',
      'neurological_feeling',
      'gastrointestinal_feeling',
      'comments',
    ];

    const rows = [
      headers,
      ...data.map((d) => [
        `${d.id.substring(0, 2)}/${d.id.substring(2, 4)}/${d.id.substring(4)}`,
        d.mitotane,
        d.cortisone,
        d.general_feeling,
        d.physical_feeling,
        d.psychological_feeling,
        d.neurological_feeling,
        d.gastrointestinal_feeling,
        d.comments,
      ]),
    ];

    let csvContent =
      'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'my_data.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const onUploadData = (e) => {
    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onload = async function (event) {
      const text = event.target.result;
      const csvData = csvFileToArray(text);

      for (let i = 0; i < csvData.length; i++) {
        const data = csvData[i];
        await setDoc(
          doc(db, 'users', user.uid, 'diary', data.id.padStart(8, '0')),
          {
            createdAt: data.createdAt
              ? Timestamp.fromMillis(Date.parse(data.createdAt))
              : Timestamp.now(),
            date: data.date
              ? Timestamp.fromMillis(Date.parse(data.date))
              : Timestamp.now(),
            breakfast: data.breakfast ? JSON.parse(data.breakfast) : [],
            lunch: data.lunch ? JSON.parse(data.lunch) : [],
            dinner: data.dinner ? JSON.parse(data.dinner) : [],
            snacks: data.snacks ? JSON.parse(data.snacks) : [],
            supplements: data.supplements ? JSON.parse(data.supplements) : [],
            water: data.water ? JSON.parse(data.water) : null,
            exercise: data.exercise ? JSON.parse(data.exercise) : null,
            alcohol: data.alcohol ? JSON.parse(data.alcohol) : null,
            energy: data.energy ? JSON.parse(data.energy) : null,
            dizziness: data.dizziness ? JSON.parse(data.dizziness) : null,
            mitotane: data.mitotane ? JSON.parse(data.mitotane) : null,
            cortisone: data.cortisone ? JSON.parse(data.cortisone) : null,
            general_feeling: data.general_feeling
              ? JSON.parse(data.general_feeling)
              : null,
            physical_feeling: data.physical_feeling
              ? JSON.parse(data.physical_feeling)
              : null,
            psychological_feeling: data.psychological_feeling
              ? JSON.parse(data.psychological_feeling)
              : null,
            neurological_feeling: data.neurological_feeling
              ? JSON.parse(data.neurological_feeling)
              : null,
            gastrointestinal_feeling: data.gastrointestinal_feeling
              ? JSON.parse(data.gastrointestinal_feeling)
              : null,
            comments: data.comments ?? '',
          }
        );
      }

      setIsLoading(false);
    };
    fileReader.readAsText(e.target.files[0]);
  };

  const displayData = [
    {
      enabled: settings.exercise,
      data: [
        {
          label: 'Alcohol',
          key: 'alcohol',
          stroke: red[400],
        },
        {
          label: 'Water',
          key: 'water',
          stroke: blue[400],
        },
        {
          label: 'Energy',
          key: 'energy',
          stroke: green[400],
        },
        {
          label: 'Dizziness',
          key: 'dizziness',
          stroke: grey[400],
        },
      ],
    },
    {
      enabled: settings.disease,
      data: [
        {
          label: 'Mitotane',
          key: 'mitotane',
          stroke: red[400],
        },
        {
          label: 'Cortisone',
          key: 'cortisone',
          stroke: blue[400],
        },
        {
          label: 'General Feeling',
          key: 'general_feeling',
          stroke: green[400],
        },
        {
          label: 'Physical Intensity',
          key: 'physical_feeling',
          stroke: grey[400],
        },
        {
          label: 'Intellectual Intensity',
          key: 'psychological_feeling',
          stroke: purple[400],
        },
        {
          label: 'Neurogical Symptoms',
          key: 'neurological_feeling',
          stroke: 'black',
        },
        {
          label: 'Gastrointestinal Symptoms',
          key: 'gastrointestinal_feeling',
          stroke: orange[400],
        },
      ],
    },
  ];

  const periodOptions = [1, 3, 7, 30];

  return (
    <Stack spacing={2} justifyContent="center">
      <Stack direction="row" justifyContent="center" sx={{ p: 2 }}>
        <Button onClick={onExportData}>Export Data</Button>

        <LoadingButton component="label" loading={isLoading}>
          Upload Data
          <input onChange={onUploadData} type="file" accept=".csv" hidden />
        </LoadingButton>
      </Stack>

      <Typography variant="h6" style={{ margin: '0 auto' }}>
        Select Date Range
      </Typography>
      <Stack direction="row" justifyContent="center" gap={8} sx={{ p: 2 }}>
        <DatePicker
          label="Start Date"
          inputFormat="DD/MM/YYYY"
          value={startDate}
          onChange={(val) => setStartDate(val)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          inputFormat="DD/MM/YYYY"
          value={endDate}
          onChange={(val) => setEndDate(val)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>

      <Typography variant="h6" style={{ margin: '0 auto' }}>
        Moving Average
      </Typography>
      <FormGroup row style={{ margin: '0 auto' }}>
        {periodOptions.map((pO, idx) => (
          <FormControlLabel
            key={idx}
            control={
              <Checkbox
                disabled={pO > data.length}
                checked={period === pO}
                onChange={() => setPeriod(pO)}
              />
            }
            label={`${pO} ${pO === 1 ? 'Day' : 'Days'}`}
          />
        ))}
      </FormGroup>
      {displayData.map(
        (dD) =>
          dD.enabled && (
            <>
              <Grid xs={12} sx={{ height: 500 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 10,
                      bottom: 10,
                      right: 10,
                      left: 10,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="id" tick={renderCustomBarLabel} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {dD.data.map(
                      (eD, idx) =>
                        checkBoxes[eD.key] && (
                          <Line
                            key={idx}
                            type="monotone"
                            dataKey={eD.key}
                            stroke={eD.stroke}
                            name={eD.label}
                          />
                        )
                    )}
                  </LineChart>
                </ResponsiveContainer>
              </Grid>
              <FormGroup row style={{ margin: '0 auto' }}>
                {dD.data.map((eD, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        checked={checkBoxes[eD.key]}
                        onChange={(event) =>
                          setCheckBoxes({
                            ...checkBoxes,
                            [eD.key]: event.target.checked,
                          })
                        }
                      />
                    }
                    label={eD.label}
                  />
                ))}
              </FormGroup>
            </>
          )
      )}
    </Stack>
  );
}
