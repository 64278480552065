import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  orderBy,
} from 'firebase/firestore';

import { Route, Routes } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Timeline from './Timeline';
import Dashboard from './Dashboard';
import Settings from './Settings';

export default function Home({ user, db }) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [nutrition, setNutrition] = useState(null);
  const [extras, setExtras] = useState(null);
  const [entries, setEntries] = useState([]);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    async function fetchSettings() {
      try {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        setSettings(docSnap.data().settings);
      } catch (e) {
        console.log(e);
      } finally {
        setHasLoaded(true);
      }
    }

    fetchSettings();
  }, [db, user?.uid]);

  useEffect(() => {
    async function fetchNutrition() {
      try {
        await getDocs(query(collection(db, 'nutrition'))).then(
          (querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            setNutrition(newData);
          }
        );
      } catch (e) {
        console.log(e);
      } finally {
        setHasLoaded(true);
      }
    }

    if (settings?.food) {
      fetchNutrition();
    }
  }, [db, settings]);

  useEffect(() => {
    async function fetchExtras() {
      try {
        const docRef = doc(db, 'extras', 'extras');
        const docSnap = await getDoc(docRef);
        setExtras(docSnap.data());
      } catch (e) {
        console.log(e);
      } finally {
        setHasLoaded(true);
      }
    }

    fetchExtras();
  }, [db]);

  useEffect(() => {
    async function fetchEntries() {
      try {
        await getDocs(
          query(
            collection(db, 'users', user.uid, 'diary'),
            orderBy('date', 'desc')
          )
        ).then((querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setEntries(newData);
        });
      } catch (e) {
        console.log(e);
      } finally {
        setHasLoaded(true);
      }
    }

    if (user?.uid) {
      fetchEntries();
    }
  }, [db, user?.uid]);

  if (!hasLoaded) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Timeline
            extras={extras}
            nutrition={nutrition}
            user={user}
            db={db}
            entries={entries}
            setEntries={setEntries}
            settings={settings}
          />
        }
      />
      <Route
        path="/dashboard"
        element={
          <Dashboard
            user={user}
            db={db}
            entries={entries}
            settings={settings}
          />
        }
      />
      <Route
        path="/settings"
        element={<Settings db={db} settingsData={settings} user={user} />}
      />
    </Routes>
  );
}
