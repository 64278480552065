import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const drawerWidth = 240;

async function signIn(auth) {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
}

function signOut(auth) {
  auth.signOut();
}

const activeStyle = {
  color: '#113f67',
  textDecoration: 'underline 2px',
  textUnderlineOffset: 5,
  textUnderlineWidth: 5,
};

const inactiveStyle = {
  color: '#113f67',
  textDecoration: 'none',
};

export default function DrawerAppBar({ auth, user }) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }} onClick={() => navigate('/')}>
        VitalisAI
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} to="/">
            <ListItemText primary={'Home'} />
          </ListItemButton>
        </ListItem>
        {user && (
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} to="/dashboard">
              <ListItemText primary={'Dashboard'} />
            </ListItemButton>
          </ListItem>
        )}
        {user && (
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} to="/settings">
              <ListItemText primary={'Settings'} />
            </ListItemButton>
          </ListItem>
        )}
        {user ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => signOut(auth)}
              sx={{ textAlign: 'center' }}
            >
              <ListItemText primary={'Sign Out'} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem disablePadding>
            <ListItemButton
              onClick={async () => await signIn(auth)}
              sx={{ textAlign: 'center' }}
            >
              <ListItemText primary={'Sign In'} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container = () => window.document.body;

  return (
    <Box sx={{ display: 'flex', height: 64 }}>
      <AppBar component="nav" sx={{ backgroundColor: '#fff' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            onClick={() => navigate('/')}
            variant="h6"
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'block' },
              cursor: 'pointer',
            }}
          >
            VitalisAI
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button>
              <NavLink
                style={({ isActive }) =>
                  isActive ? activeStyle : inactiveStyle
                }
                to="/"
              >
                Home
              </NavLink>
            </Button>
            {user && (
              <Button>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : inactiveStyle
                  }
                  to="dashboard"
                >
                  Dashboard
                </NavLink>
              </Button>
            )}
            {user && (
              <Button>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : inactiveStyle
                  }
                  to="settings"
                >
                  Settings
                </NavLink>
              </Button>
            )}
            {!user ? (
              <Button onClick={() => signIn(auth)}>Log In</Button>
            ) : (
              <Button onClick={() => signOut(auth)}>Log out</Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
