import React, { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

export default function Settings({ db, user, settingsData }) {
  const [settings, setSettings] = useState(settingsData);

  const handleOnSubmit = async () => {
    await updateDoc(doc(db, 'users', user.uid), {
      settings,
    });
    window.location.reload();
  };

  const handleChange = (name, value) => {
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={12}
      md={6}
      mdOffset={3}
      justifyContent="center"
    >
      <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
        <FormGroup>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings['food']}
                    onChange={(e) => handleChange('food', e.target.checked)}
                  />
                }
                label="Food"
              />
            </Grid>

            <Grid xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings['exercise']}
                    onChange={(e) => handleChange('exercise', e.target.checked)}
                  />
                }
                label="Exercise"
              />
            </Grid>

            <Grid xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings['disease']}
                    onChange={(e) => handleChange('disease', e.target.checked)}
                  />
                }
                label="Mitotane Cure"
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Paper>

      <Button type="submit" onClick={handleOnSubmit} sx={{ m: 4 }}>
        Save
      </Button>
    </Grid>
  );
}
