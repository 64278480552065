import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// import LandingImage from './../img/landing-1.png';

function signIn(auth) {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider);
}

export default function Landing({ auth }) {
  return (
    <Stack
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      <Typography variant="h3">VitalisAI</Typography>
      <Slide direction="up" in={true} timeout={1000}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ p: 4 }}
        >
          <Button onClick={() => signIn(auth)} variant="contained">
            Log In
          </Button>
          {/* <img
            alt="Vitalis AI logo"
            style={{
              width: '400px',
              transform: isSmallScreen ? 'scale(0.5)' : 'none',
            }}
            src={LandingImage}
          ></img>
          <Typography variant="subtitle1">
            Welcome to VitalisAI, a startup dedicated to helping cancer patients
            keep track of their health data. We understand how overwhelming it
            can be to keep track of all the information related to your
            treatment. That's why we've created an intuitive and user-friendly
            platform that makes it easy to monitor your physical and mental
            health, keep track of medications, and track progress over time.
          </Typography> */}
        </Stack>
      </Slide>
      <Divider variant="middle"></Divider>
    </Stack>
  );
}
