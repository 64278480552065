import React, { useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';

import Entry from './Entry';
import TimelineCard from './TimelineCard';

const PAGE_SIZE = 5;

export default function Timeline({
  nutrition,
  extras,
  user,
  db,
  entries,
  setEntries,
  settings,
}) {
  const [entriesIndex, setEntriesIndex] = useState(1);
  const [isEntryOpen, setIsEntryOpen] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const fetchMoreData = () => {
    setEntriesIndex(entriesIndex + 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <InfiniteScroll
        dataLength={entriesIndex * PAGE_SIZE}
        next={fetchMoreData}
        hasMore={entries.length > entriesIndex * PAGE_SIZE}
        loader={
          <Stack sx={{ m: 8 }} alignItems="center">
            <CircularProgress />
          </Stack>
        }
        style={{ overflow: 'invisible' }}
      >
        <Grid container spacing={2}>
          {entries.slice(0, entriesIndex * PAGE_SIZE).map((entry) => (
            <TimelineCard
              key={entry.id}
              entry={entry}
              settings={settings}
              setUpdateData={setUpdateData}
              setIsEntryOpen={setIsEntryOpen}
            />
          ))}
        </Grid>
      </InfiniteScroll>

      {user && (
        <Fab
          color="primary"
          onClick={() => {
            setUpdateData(null);
            setIsEntryOpen(true);
          }}
          sx={{ position: 'fixed', bottom: 50 }}
        >
          <AddIcon />
        </Fab>
      )}
      {isEntryOpen && (
        <Entry
          setEntries={setEntries}
          user={user}
          db={db}
          isOpen={isEntryOpen}
          setIsOpen={setIsEntryOpen}
          nutrition={nutrition}
          extras={extras}
          updateData={updateData}
          settings={settings}
        />
      )}
    </Box>
  );
}
